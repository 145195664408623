import { PluggedFireStore } from '../../lib/firebase'
import { doc, updateDoc, getDoc } from 'firebase/firestore'


const deleteFromCampaign = async (data) => {
  const { partnerId, campaignId, idToRemove } = data;

  try {
    const PartnerRef = doc(PluggedFireStore, 'adPartners', partnerId);
    const docSnap = await getDoc(PartnerRef);

    if (docSnap.exists()) {
      // update partner
      const docData = docSnap.data();
      const filtered = docData.campaigns[campaignId].eligible.filter((item) => item !== idToRemove);
      await updateDoc(PartnerRef, {
        [`campaigns.${campaignId}.eligible`]: filtered
      });
    }

    return { text: 'complete', code: 200 }
  } catch (error) {
    console.error('deleteConnection error:', error);
    return { text: 'failed', code: 500 }
  }
}

const deleteAllConnections = async (data) => {
  const {partners, id} = data;

  Object.keys(partners).forEach( async (partnerId) => {
    // loop through partners for this episode
    const thisItem = partners[partnerId];
    thisItem.forEach(async (campaignId) => {
      console.log('ep i', thisItem);
    // loop through all partner campaigns and remove episode from this episode
      await deleteFromCampaign({
        partnerId: partnerId, 
        campaignId: campaignId, 
        idToRemove: id
      });
    })
  });
}
export {deleteFromCampaign, deleteAllConnections}