import { useState, useEffect } from 'react'
import { TagsInput } from 'react-tag-input-component'
import { useParams, useNavigate } from 'react-router-dom'
import { useFilePicker } from 'use-file-picker'
import {
  FileAmountLimitValidator,
  FileTypeValidator,
  FileSizeValidator,
  ImageDimensionsValidator,
} from 'use-file-picker/validators'
import Toast from './modals/toast'
import { getByFieldQuery } from '../data/getter'
import { setNewEpisode, setUpdateFields, uploadAsset } from '../data/setters'
import ToggleSwitch from './form/toggle'
import ConfirmationModal from './modals/confirmation'

const EpisodeInfoForm = (props) => {
  let { seriesId, episodeId } = useParams();
  const [published, setPublished] = useState(false);
  const [seriesInfo, setSeriesInfo] = useState("");
  const [episodeName, setEpisodeName] = useState("");
  const [episodeSubtitle, setEpisodeSubtitle] = useState("");
  const [episodeNumber, setEpisodeNumber] = useState(1);
  const [episodeVideoURL, setEpisodeVideoURL] = useState("");
  const [episodePoster, setEpisodePoster] = useState("");
  const [adSpots, setAdSpots] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [formError, setFormError] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showToast, setShowToast] = useState(null);
  const navigate = useNavigate();

  let defaultSeriesData = {
    advertisement : {
      buckets : [],
      placements: props.defaultData?.advertisement?.placements || []
    },
    catalogId: props.defaultData?.catalogId || "",
    episodeNumber: props.defaultData?.episodeNumber || "0",
    poster: props.defaultData?.poster || "",
    published: props.defaultData?.published || false,
    title: props.defaultData?.title || "",
    subtitle: props.defaultData?.subtitle || "",
    videoURL: props.defaultData?.videoURL || ""
  }

  const { openFilePicker, filesContent } = useFilePicker({
    readAs: 'DataURL',
    accept: 'image/*',
    multiple: false,
    validators: [
      new FileAmountLimitValidator({ max: 1 }),
      new FileTypeValidator(['jpg', 'png']),
      new FileSizeValidator({ maxFileSize: 50 * 1024 * 1024 /* 50 MB */ }),
      new ImageDimensionsValidator({
        maxHeight: 1600, // in pixels
        maxWidth: 2400,
        minHeight: 400,
        minWidth: 600,
      }),
    ],
  });

  const fetchSeries = async () => {
    if(seriesId) {
      const seriesData = await getByFieldQuery({
        collectionId: "catalog",
        fieldName: "cKey",
        fieldValue: seriesId
      });
      setSeriesInfo(seriesData);
    } 
  }

  const cleanUpForm = () => {
    setPublished(false);
    setEpisodeName("");
    setEpisodeSubtitle("");
    setEpisodeNumber(0);
    setEpisodeVideoURL("");
    setEpisodePoster("");
    setAdSpots([]);
    setShowConfirmationModal(false);
  }

  const handleChange = () => {
    window.scroll(0,0);
    setShowToast("show");
    setTimeout(() => {
      setShowToast(null);
    },1500);
  }

  const handleEpisodeData = async () => {
    let posterImage = '';
    if(!episodeName || !episodeVideoURL) {
      setFormError(true);
      return;
    }
    setIsLoading(true);
    
    if(episodePoster) {
      posterImage =  await uploadAsset(episodePoster, '/thumbnails');
    }
    const payload = {
      advertisement : {
        buckets : [],
        placements: adSpots || defaultSeriesData.advertisement?.placements
      },
      catalogId: seriesInfo[0].entryId,
      episodeNumber: episodeNumber || defaultSeriesData.episodeNumber,
      poster: posterImage || defaultSeriesData.poster,
      published: published || defaultSeriesData.published,
      title: episodeName || defaultSeriesData.title,
      subtitle: episodeSubtitle || defaultSeriesData.subtitle,
      videoURL: episodeVideoURL || defaultSeriesData.videoURL
    }

    if(!!props.defaultData?.entryId) {
      await setUpdateFields(props.defaultData.entryId, 'episodes', payload);
    } else {
      await setNewEpisode(payload);
    }
    handleChange();
    setIsLoading(false);
    setShowConfirmationModal(true);
    
    if(props.onSubmit) {
      props.onSubmit();
    }
  }
  useEffect(() => {
    fetchSeries();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[seriesId, episodeId]);

  useEffect(() => {
    const uploadImageToFirebase = async () => {
      setEpisodePoster(filesContent[0].content);
      setIsLoading(false);
    }

    if(filesContent.length){
      uploadImageToFirebase();
    }

  },[filesContent]);

  if(!seriesInfo){
    return;
  }

  return (
    <div>
      <Toast title={`Series has been sucessfully ${props.defaultData ? 'updated' : 'added'}.`} trigger={showToast} />
      {isLoading && <div className="position-fixed bg-white p-4 rounded top-50 start-50 border">
        <div class="text-cente text-primary">
          <div class="spinner-border" role="status">
          </div>
        </div>
      </div>}
      {showConfirmationModal &&
        <ConfirmationModal
            modalTitle={`${props.defaultData?.entryId ? 'Update' : 'Create' } Episode`}
            confirmationAction={cleanUpForm}
            cancelAction={() => navigate(`/series/${seriesId}`)}
            title={`Your episode has been ${props.defaultData?.entryId ? 'updated' : 'created' }`}
            subtitle={props.defaultData?.entryId ? 'You will now see this change in the series.' : 'What would you like to do next?' }
            cancelButton="Go to series"
            confirmationButton="Create another episode"
        />
      }
      <form id="new-series-form">
        <section>
          <div className="alert alert-primary" role="alert">
            You are {props.defaultData?.entryId ? 'updating' : 'creating' } an episode for: <span className="fw-bold">{seriesInfo[0].title}</span>
          </div>
          <ToggleSwitch onChange={() => setPublished(!published)} enabled={published || defaultSeriesData.published} label="Publish" classNames="mb-4" />
          <div>
            <label htmlFor="episode-name" className="App-Input-Label mb-2 d-block">Episode Name</label>
            <input
              id="episode-name"
              type="text"
              name="episode-name"
              value={episodeName || defaultSeriesData.title}
              placeholder="cool episode name"
              className={`form-control App-Input mb-4 ${formError && !episodeName.length ? 'border-danger' : ''}`}
              onInput={(e) => setEpisodeName(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="series-subtitle" className="App-Input-Label mb-2 d-block">Episode subtitle</label>
            <input id="series-subtitle"
              type="text"
              name="series-name"
              value={episodeSubtitle || defaultSeriesData.subtitle}
              placeholder="(ex: 3 part mini-series)"
              className="form-control App-Input mb-4"
              onInput={(e) => setEpisodeSubtitle(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="series-number" className="App-Input-Label mb-2 d-block">Episode number</label>
            <input id="series-number"
              type="number"
              name="series-name"
              value={episodeNumber || defaultSeriesData.episodeNumber}
              placeholder="1"
              className="form-control App-Input mb-4"
              min={1}
              onInput={(e) => setEpisodeNumber(e.target.value)}
            />
          </div>
          <div className="row mb-2">
            <div className="col-6">
              <label className="App-Input-Label mb-2 d-block">Episode Thumbnail</label>
              <button
                className="btn btn-primary mt-4"
                type="button"
                onClick={openFilePicker}>
                {props.defaultData?.entryId ? 'Update' : 'Add' } Photo
              </button>
            </div>
            <div className="col-6">
              <div className="p-2">
                {(episodePoster || defaultSeriesData.poster) && <img src={episodePoster || defaultSeriesData.poster} alt="some tag" className="responsive-image uploaded-image-preview" /> }
              </div>
            </div>
          </div>
          <div>
            <label htmlFor="episode-video-link" className="App-Input-Label mb-2 d-block">Video link</label>
            <input id="episode-video-link"
              type="text"
              name="episode-video-link"
              value={episodeVideoURL || defaultSeriesData.videoURL}
              className={`form-control App-Input App-Input-File mb-4 ${formError && !episodeVideoURL.length ? 'border-danger' : ''}`}
              onChange={(e) => setEpisodeVideoURL(e.target.value)}
            />
          </div>
        </section>
        <section>
          <hr className="line-break" />
          <div className="mb-4">
            <span className="App-Input-Label mb-2 d-block">Ad Placement Spots</span>
            <TagsInput
              value={adSpots.length ? adSpots : defaultSeriesData.advertisement?.placements}
              onChange={setAdSpots}
              name="collaborators"
              placeHolder="time is in seconds"
            />
            <em className="text-small fw-lighter">press enter to add new tag</em>
          </div>
        </section>
        <button type="button" className="btn btn-primary" name="submit" onClick={handleEpisodeData}>Done, {props.defaultData?.entryId ? 'update' : 'create' } the episode</button>
      </form>
    </div>
  );
}

export default EpisodeInfoForm;