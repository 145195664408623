import BunnyNetLogo from '../assets/images/bunnynet.png'
import AnalyticsLogo from '../assets/images/analytics.png'
import KlaviyoLogo from '../assets/images/klaviyo.png'

const DashBoardPage = () => {
  return (
    <div>
      <h2 className="mb-5">Plugged Media Data Manager Tool</h2>
      <h4 className="mb-4">External Tools</h4>
      <hr />
      <div className="row d-flex align-items-center">
        <div className="col-2 d-flex justify-content-center">
          <img src={BunnyNetLogo} alt="bunny.net" className="dashboard-image" />
        </div>
        <div className="col-7 px-4">
          <p>
            <span>Videos are hosted on Bunny</span>
            <span className="d-block text-small text-secondary mb-2">login via Google SSO with pluggedmediaco.com </span>
          </p>
        </div>
        <div className="col-3">
          <a
            href="https://dash.bunny.net/"
            target="_blank"
            rel="noreferrer"
            className="btn btn-outline-dark w-100"
          >Go to Bunny</a>
        </div>
      </div>
      <hr />
      <div className="row d-flex align-items-center">
        <div className="col-2 d-flex justify-content-center">
          <img src={AnalyticsLogo} alt="analytics.google.com" className="dashboard-image" />
        </div>
        <div className="col-7 px-4">
          <p>
            <span>Analytics available via firebase or Google Analytics</span>
            <span className="d-block text-small text-secondary mb-2">login via Google SSO with pluggedmediaco.com </span>
          </p>
        </div>
        <div className="col-3">
          <a 
            href="https://analytics.google.com/analytics/web/?authuser=3&hl=en#/p425010687/reports/dashboard?r=firebase-overview"
            target="_blank"
            rel="noreferrer"
            className="btn btn-outline-dark w-100"
          >Go to Analytics</a>
        </div>
      </div>
      <hr />
      <div className="row d-flex align-items-center">
        <div className="col-2 d-flex justify-content-center">
          <img src={KlaviyoLogo} alt="klaviyo.com" className="dashboard-image" />
        </div>
        <div className="col-7 px-4">
          <p>
            <span>Email Marketing campaigns are managed via Klaviyo</span>
            <span className="d-block text-small text-secondary mb-2">login via email with pluggedmediaco.com </span>
          </p>
        </div>
        <div className="col-3">
          <a href="https://dash.bunny.net/" target="_blank" rel="noreferrer" className="btn btn-outline-dark w-100">Go to Klaviyo</a>
        </div>
      </div>
    </div>
  );
}

export default DashBoardPage;
