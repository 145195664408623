import { useEffect, useState } from 'react'
import { getCollection } from '../../data/getter'
import { Link } from 'react-router-dom'
import NoResultsBanner from '../../components/errors/emptyData'
import Loading from '../../components/loading'
import FourOhFour from '../../components/errors/fourOhFour'
import { ConvertCampaignObject } from '../../utils/capaignObject'

const ManagePartnerPage = () => {
  const [partners, setPartners] = useState([]);
  const [inActivePartners, setInActivePartners] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const fetchPartners = async () => {
    const partnerData = await getCollection("adPartners");
    partnerData.forEach((partner) => {
      if(!partner.active) {
        setInActivePartners([...inActivePartners, partner]);
      } else {
        setPartners([...partners, partner]);
      }
      
    })
    setIsLoading(false);
  }

  useEffect(() => {
    fetchPartners();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);


  if(!isLoading && !partners.length) {
    return <FourOhFour />
  }

  if(isLoading) {
    return <Loading />
  }

  return (
    <div>
      <div className="position-relative w-100">
        <h1 className="text-uppercase fw-bolder App-Page-Title">Manage Partners</h1>
        <Link to={`/new/partner`} className="btn btn-outline-dark position-absolute top-0 end-0 me-2">Create new partner</Link>
      </div>
      <section className="container">
        <div className="d-flex border-bottom my-3 pb-2 row App-Flex-Table">
          <div className="col-2">
            <span className="fw-semibold fs-5 secondary-font">Partner</span>
          </div>
          <div className="col-3">
            <span className="fw-semibold fs-5 secondary-font">Contact Name</span>
          </div>
          <div className="col-4">
            <span className="fw-semibold fs-5 secondary-font">Contact Email</span>
          </div>
          <div className="col-1 d-flex justify-content-center">
            <span className="fw-semibold fs-5 secondary-font"><i className="bi bi-diagram-2 fs-4"></i></span>
          </div>
          <div className="col-2">&nbsp;</div>
        </div>
      </section>
      <section className="container">
        {partners && !!partners.length ? partners.map((partner) => {
          const campaigns = ConvertCampaignObject(partner.campaigns);
          return (
          <div className="row border-bottom mb-2 pb-2 App-Flex-Table App-Flex-Table__row" key={partner.entryId}>
            <div className="col-2">
              <span>{partner.company}</span>
            </div>
            <div className="col-3">
              <span>{partner.contactName}</span>
            </div>
            <div className="col-4">
              <span>{partner.contactEmail}</span>
            </div>
            <div className="col-1 d-flex justify-content-center">
              <span>{campaigns.length || 0}</span>
            </div>
            <div className="col-2 text-center"><Link to={`/partners/${partner.entryId}`} className="btn btn-dark btn-sm">Manage</Link></div>
          </div>
        )
        }) : <NoResultsBanner name="partners" />}
      </section>
      <section className="container">
        <div className="my-4"><h5>Inactive Partners</h5></div>
        {!!inActivePartners ? inActivePartners.map((partner) => {
          const campaigns = ConvertCampaignObject(partner.campaigns);
          return (
            <div className="row border-bottom mb-2 pb-2 App-Inactive" key={partner.entryId}>
              <div className="col-2">
                <span>{partner.company}</span>
              </div>
              <div className="col-3">
                <span>{partner.contactName}</span>
              </div>
              <div className="col-4">
                <span>{partner.contactEmail}</span>
              </div>
              <div className="col-1 d-flex justify-content-center">
                <span>{campaigns.length || 0}</span>
              </div>
              <div className="col-2 text-center"><Link to={`/partners/${partner.entryId}`} className="btn btn-outline-secondary btn-sm">manage</Link></div>
            </div>
          )
        }) :  <div>No results found.</div>
        }
      </section>
    </div>
  );
}

export default ManagePartnerPage;