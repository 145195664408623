import {useEffect, useState} from 'react'
import { getCollection } from '../../data/getter'
import NoResultsBanner from '../../components/errors/emptyData'
import Loading from '../../components/loading'
import FallBackCover from '../../assets/fallbackCover.png'
import ToggleSwitch from '../../components/form/toggle'
import Toast from '../../components/modals/toast'
import { setUpdateFields } from '../../data/setters'

const ManageFeaturedSeriesPage = () => {
  const [catalogData, setCatalogData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [showToast, setShowToast] = useState(null);

  const fetchCatalog = async () => {
    const catalogItems = await getCollection("catalog");
    setCatalogData(catalogItems);
    setIsLoading(false);
  }

  useEffect(() => {
    fetchCatalog();
  },[]);

  const handleFeatureToggle = async (seriesId, toggleVal) => {
    await setUpdateFields(seriesId,'catalog', {featured: toggleVal})
    fetchCatalog();
    handleChange();
  }

  const handleChange = () => {
    setShowToast("show");
    setTimeout(() => {
      setShowToast(null);
    },1500);
  }

  if(isLoading) {
    return <Loading />
  }

  return (
    <div>
      <Toast title="Featured series updated" trigger={showToast} />
      <h1 className="text-uppercase fw-bolder App-Page-Title">Manage Featured Series</h1>
      <section className="container">
        <div className="d-flex border-bottom my-3 pb-2 row App-Flex-Table">
          <div className="col-2">
            <span className="fw-semibold fs-5 secondary-font">Thumbnail</span>
          </div>
          <div className="col-6">
            <span className="fw-semibold fs-5 secondary-font">Title</span>
          </div>
          <div className="col-2">
            <span className="fw-semibold fs-5 secondary-font">Episodes</span>
          </div>
          <div className="col-2">&nbsp;</div>
        </div>
      </section>
      <section className="container">
        {catalogData && !!catalogData.length ? catalogData.map((series) => {
          return (
          <div className="row border-bottom mb-2 pb-2 App-Flex-Table App-Flex-Table__row" key={series.cKey}>
            <div className="col-2">
              <img className="App-Thumbnail-Poster App-Thumbnail-Poster__smaller" src={series.cover || FallBackCover} alt={`${series.title} cover`} />
            </div>
            <div className="col-6 align-self-center">
              <h4 className="text-capitalize mb-0">{series.title}</h4>
              <span className="fs-6 text-secondary">{series.subtitle}</span>
            </div>
            <div className="col-2 align-self-center">
              <span>{series.episodes.length}</span>
            </div>
            <div className="col-2 align-self-center">
              <ToggleSwitch onChange={() => handleFeatureToggle(series.entryId, !series.featured)} enabled={series.featured} />
            </div>
          </div>
        )
        }) : <NoResultsBanner name="series" />}
      </section>
    </div>
  );
}

export default ManageFeaturedSeriesPage;